import { SelectItemInterface } from '@default-application-app/core/interfaces/selectItemInterface';

export const SUBJECT_TBA = 'TBA';
export const SUBJECT_TBU = 'TBU';
export const SUBJECT_IBAN_TRANSFER_FEE = 'IBANFEES';
export const SUBJECT_IBAN_TBU_OUTGOING = 'IBANTBUOUTGOING';
export const SUBJECT_GROUP_TBU = 'GROUPTBU';
export const SUBJECT_OWT = 'OWT';
export const SUBJECT_CFT = 'CFT';

export const SUBJECT_CO = 'CO';

export const SUBJECT_CI = 'CI';

export const SUBJECT_IP = 'IP';
export const SUBJECT_CA = 'CA';
export const SUBJECT_DA = 'DA';

export const SUBJECT_IBAN_TBU_INCOMING = "IBANTBUINCOMING";

export const SUBJECT_TEXTS = {
  [SUBJECT_TBA]: 'transfer_between_accounts',
  [SUBJECT_TBU]: 'transfer_between_users',
  [SUBJECT_OWT]: 'outgoing_wire_transfer',
  [SUBJECT_CFT]: 'card_funding_transfer',
  [SUBJECT_CA]: 'credit_account',
  [SUBJECT_DA]: 'debit_account',
  [SUBJECT_CO]: 'co_transfer',
  [SUBJECT_CI]: 'ci_transfer',
  [SUBJECT_IBAN_TBU_INCOMING]: 'iban_tbu_incoming',
  [SUBJECT_IBAN_TRANSFER_FEE]: 'iban_fees',
  [SUBJECT_IBAN_TBU_OUTGOING]: 'iban_tbu_outgoing',
};
// SUBJECT_CA, SUBJECT_DA is not in the list, as it was specified by PM
export const REQUEST_TYPES: SelectItemInterface[] = [
  { key: '', value: 'all_types' },
  { key: SUBJECT_TBA, value: SUBJECT_TEXTS[SUBJECT_TBA] },
  { key: SUBJECT_TBU, value: SUBJECT_TEXTS[SUBJECT_TBU] },
  { key: SUBJECT_OWT, value: SUBJECT_TEXTS[SUBJECT_OWT] },
  { key: SUBJECT_IBAN_TRANSFER_FEE, value: SUBJECT_TEXTS[SUBJECT_IBAN_TRANSFER_FEE] },
  { key: SUBJECT_IBAN_TBU_OUTGOING, value: SUBJECT_TEXTS[SUBJECT_IBAN_TBU_OUTGOING] },
];
