import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IbanApiService } from './iban-api.service';
import { BehaviorSubject } from 'rxjs';
import { AllTransactionReportFilterInterface } from '@interfaces/admin-reports/allTransactionReportFilter.interface';

@Injectable({
  providedIn: 'root',
})
export class IbanService {
  constructor(protected apiService: IbanApiService) {}

  /**
   * Fetches IBAN requests with optional pagination and filtering parameters.
   * @param pageSize Number of records per page (optional)
   * @param currentPage Current page number (optional)
   * @param dateFrom Start date filter (optional)
   * @param dateTo End date filter (optional)
   */
  public getIbanRequest(
    pageSize?: number,
    currentPage?: number,
    dateFrom?: string,
    dateTo?: string
  ): Observable<any> {
    // Build the parameters object only if they are provided
    const params: any = {};
    if (pageSize) params['page[size]'] = pageSize;
    if (currentPage) params['page[number]'] = currentPage;
    if (dateFrom) params['filters[dateFrom]'] = dateFrom;
    if (dateTo) params['filters[dateTo]'] = dateTo;
    
    return this.apiService.apiGetIbanRequests(params);
    
  }

  private accountIdSource = new BehaviorSubject<string>(''); // Default value
  public accountId$ = this.accountIdSource.asObservable();

  setAccountId(accountId: string): void {
    this.accountIdSource.next(accountId);
  }

  public getPrevIbanRequestByUserId(userID: string): Observable<any> {
    const params: any = {};
    params['user-id'] = userID;
    return this.apiService
      .apigetPrevIbanRequestByUserId(params);
  }

  public getIbanTransaction(accountID: string, createdAt: string): Observable<any> {
    const params: any = {};
    params['account-id'] = accountID;
    params['created-at'] = createdAt;
    return this.apiService
      .apigetIbanTransaction(params);
  }

  public getIbanCycles(): Observable<any> {
    const params: any = {};
    return this.apiService
      .apigetIbanCycles(params);
  }

  public approveIbanRequest(requestId: string, ibanNumber: string): Observable<any> {
    return this.apiService
      .apiApproveIbanRequest(requestId, ibanNumber);
  }

  public rejectIbanRequest(requestId: string, ibanRejectionReason: string): Observable<any> {
    return this.apiService
      .apiRejectIbanRequest(requestId, ibanRejectionReason);
  }

  public getRequestById(requestId: string): Observable<any> {
    return this.apiService
      .apiGetRequestById(requestId);
  }

  public getIbanTransactionsByCycleId(cycleId: string): Observable<any> {
    return this.apiService
      .apigetIbanTransactionsByCycleId(cycleId);
  }

  public getCycleByCycleId(cycleId: string): Observable<any> {
    return this.apiService
      .apigetCycleByCycleId(cycleId);
  }

  public GetIbanPendingTransactions(filter: AllTransactionReportFilterInterface,) {
    return this.apiService.apiGetIbanPendingTransactions(filter);
  }

  public getApprovedIbanUsers(
    pageSize?: number,
    currentPage?: number
  ): Observable<any> {
    // Build the parameters object only if they are provided
    const params: any = {};
    if (pageSize) params['page[size]'] = pageSize;
    if (currentPage) params['page[number]'] = currentPage;
    
    return this.apiService.apiGetApprovedIbanUsers(params);
    
  }

  public getIbanTransactionById(requestId: string): Observable<any> {
    return this.apiService
      .apiGetIbanTransactionById(requestId);
  }

  public rejectIbanTransaction(requestId: string, reason: string): Observable<any> {
    return this.apiService
      .apiRejectIbanTransaction(requestId, reason);
  }

  public approveIbanTransaction(requestId: string, referenceId: string, feesAmount: string): Observable<any> {
    return this.apiService
      .apiApproveIbanTransaction(requestId, referenceId, feesAmount);
  }

  public getUserLimitsByIdAndRole(userId: string, userRole: string): Observable<any> {
    return this.apiService.apiGetUserLimitsByIdAndRole(userId, userRole);
  }

  public updateUserSpecificLimit(userId: string, limitId: string, amount: string): Observable<any> {
    return this.apiService.apiUpdateUserSpecificLimit(userId, limitId, amount);
  }

  public createReceivedWireTransfer(accountIdTo: number, accountIbanFrom: string, accountIbanTo: string, incomingAmount: string, ReferenceNumber: string,): Observable<any> {
    return this.apiService.apiCreateReceivedWireTransfer(accountIdTo, accountIbanFrom, accountIbanTo, incomingAmount, ReferenceNumber)
  }

  public closePaymentCycle(cycleId: string, otp: string){
    return this.apiService.apiClosePaymentCycle(cycleId, otp)
  }

  public getCurrentCycleId(){
    return this.apiService.apiGetCurrentCycleId();
  }

}
