import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiCallerService } from '@default-application-app/core/services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';
import { AllTransactionReportFilterInterface } from '@interfaces/admin-reports/allTransactionReportFilter.interface';
import { PaginationService } from '@services/pagination/pagination.service';
import moment from 'moment';
import { DATEPICKER_RESPONSE_FORMAT } from '@constants/datepickerBaseOptions';
import { queryParamsStringify } from '@helpers/queryParamsHelpers';
import { Observable } from 'rxjs';

@Injectable()
export class IbanApiService {
  constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) { }

  /**
   * Makes a GET request to fetch IBAN requests with optional parameters.
   * @param params Object containing optional query parameters for pagination and filtering
   */

  public static sanitizeFilter(requestParams: any): AllTransactionReportFilterInterface {
    return {
      query: requestParams.query || '',
      dateFrom: IbanApiService.getFromDate(requestParams),
      dateTo: IbanApiService.getToDate(requestParams),
      page: requestParams.page || '1',
      limit: requestParams.size || PaginationService.defaultLimit.toString(),
    };
  }

  private static getFromDate(parameters: any): string {
    return parameters && parameters.dateFrom && IbanApiService.validateDate(parameters.dateFrom)
      ? parameters.dateFrom
      : '';
  }

  private static getToDate(parameters: any): string {
    return parameters && parameters.dateTo && IbanApiService.validateDate(parameters.dateTo)
      ? parameters.dateTo
      : '';
  }

  private static validateDate(date: string): boolean {
    return moment(date, DATEPICKER_RESPONSE_FORMAT, true).isValid();
  }

  private transformFilterToQuery(filter: AllTransactionReportFilterInterface): {
    page: { number: string; size: string };
    filters?: { dateTo?: string; dateFrom?: string; query?: string };
  } {
    const queryFilter: {
      page: { number: string; size: string };
      filters?: { dateTo?: string; dateFrom?: string; query?: string };
    } = {
      page: {
        number: filter.page,
        size: filter.limit,
      },
    };

    if (filter.dateFrom || filter.dateTo || filter.query) {
      queryFilter.filters = {};
      if (filter.dateFrom) {
        queryFilter.filters.dateFrom = moment(filter.dateFrom).startOf('day').utc().format();
      }
      if (filter.dateTo) {
        queryFilter.filters.dateTo = moment(filter.dateTo).endOf('day').utc().format();
      }
      if (filter.query) {
        queryFilter.filters.query = filter.query;
      }
    }
    return queryFilter;
  }

  public apiGetIbanRequests(params: { [key: string]: any }) {
    const httpParams = new HttpParams({ fromObject: params });

    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getIbanRequest, { params: httpParams }),
      'apiGetIbanRequests',
    );
  }

  public apigetPrevIbanRequestByUserId(params: { [key: string]: any }) {
    const httpParams = new HttpParams({ fromObject: params });
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getPrevIbanRequestByUserId,
          {
            params: httpParams
          }
        ),
      'getPrevIbanRequestByUserId',
    );
  }

  public apigetIbanTransaction(params: { [key: string]: any }) {
    const httpParams = new HttpParams({ fromObject: params });
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getIbanTransaction,
          {
            params: httpParams
          }
        ),
      'getIbanTransaction',
    );
  }

  public apigetIbanCycles(params: { [key: string]: any }) {
    const httpParams = new HttpParams({ fromObject: params });
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getIbanCycles,
          {
            params: httpParams
          }
        ),
      'getIbanCycles',
    );
  }

  public apigetIbanTransactionsByCycleId(cycleId: string) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getTransactionsByCycleId(cycleId),
        ),
      'getIbanTransactionsByCycleId',
    );
  }

  public apigetCycleByCycleId(cycleId: string) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getCycleByCycleId(cycleId),
        ),
      'getCycleByCycleId',
    );
  }

  public apiApproveIbanRequest(requestId: string, ibanNumber: string) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.iban.approveIbanRequest,
          {
            'request-id': requestId,
            'iban-number': ibanNumber
          }
        ),
      'apiApproveIbanRequest',
    );
  }

  public apiRejectIbanRequest(requestId: string, IbanRejectionReason: string) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.iban.rejectIbanRequest,
          {
            'request-id': requestId,
            'rejection-reason': IbanRejectionReason
          }
        ),
      'apiRejectIbanRequest',
    );
  }

  public apiGetRequestById(requestId: string) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getRequestById(requestId),
        ),
      'apiGetRequestById',
    );
  }

  public apiGetIbanPendingTransactions(filter: AllTransactionReportFilterInterface,) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getIbanPendingTransactions,
          { params: queryParamsStringify(this.transformFilterToQuery(IbanApiService.sanitizeFilter(filter)), false) }
        ),
      'apiGetIbanPendingTransactions',
    );
  }

  public apiGetApprovedIbanUsers(params: { [key: string]: any }) {
    const httpParams = new HttpParams({ fromObject: params });

    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getApprovedIbanUsers, { params: httpParams }),
      'apiGetApprovedIbanUsers',
    );
  }

  public apiGetIbanTransactionById(requestId: string) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getIbanTransactionById(requestId),
        ),
      'apiGetIbanTransactionById',
    );
  }

  public apiRejectIbanTransaction(requestId: string, reason: string) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.iban.rejectIbanTransaction(requestId),
          {
            'reason': reason
          }
        ),
      'apiRejectIbanTransaction',
    );
  }

  public apiApproveIbanTransaction(requestId: string, referenceId: string, feesAmount: string) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.iban.approveIbanTransaction(requestId),
          {
            'referenceId': referenceId,
            'feesAmount': feesAmount
          }
        ),
      'apiApproveIbanTransaction',
    );
  }

  public apiGetUserLimitsByIdAndRole(userId: string, userRole: string) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getUserLimitsByIdAndRole(userId, userRole),
        ),
      'apiGetUserLimitsByIdAndRole',
    );
  }

  public apiUpdateUserSpecificLimit(userId: string, limitId: string, amount: string) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.iban.createOrUpdateUserSpecificLimit,
          {
            'user-id': userId,
            'limit-id': limitId,
            'amount': amount
          }
        ),
      'apiUpdateUserSpecificLimit',
    );
  }

  public apiCreateReceivedWireTransfer(accountIdTo: number, accountIbanFrom: string, accountIbanTo: string, incomingAmount: string, ReferenceNumber: string,) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.iban.createReceivedWireTransfer,
          {
            'accountIdTo': accountIdTo,
            'accountIbanFrom': accountIbanFrom,
            'accountIbanTo': accountIbanTo,
            'incomingAmount': incomingAmount,
            'ReferenceNumber': ReferenceNumber
          }
        ),
      'apiCreateReceivedWireTransfer',
    );
  }

  public apiClosePaymentCycle(cycleId: string, otp: string) {
    const headers: {} = {};

    headers['X-OTP'] = otp;
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.iban.closePaymentCycle(cycleId), {}, { headers }),
      'apiClosePaymentCycle',
    );
  }

  public apiGetCurrentCycleId(): Observable<any> {
    return this.apiCallerService.call(
      () => 
        this.http.get(this.configService.config.api.iban.getCurrentCycleId),
      'apiGetCurrentCycleId'
    )
  }
  

}
