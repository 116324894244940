import { CurrencyAmount } from '../currency-amount';
import { Transaction } from '../transaction';

export class AdditionalDetailField {
  constructor(public name: string, public value: string) { }
}

export class AdditionalDetail {
  constructor(public title: string, public fields: AdditionalDetailField[]) { }
}

export class SystemDetails {
  public id: string;

  public createdAt: string;

  public statusChangedAt: string;

  public updatedAt: string;

  public firstName: string;

  public lastName: string;

  public email: string;

  public sourceAccountNumber: string;

  public destinationAccountNumber: string;

  public destinationAccount: any;

  public sourceAccount: any;

  public username: string;

  public status: string;

  public reason: string;

  public subject: string;

  public isInitiatedBySystem: boolean;

  public referenceNo?: string;

  public referenceCurrencyCode?: string;

  public delegateType?: string;

  public delegateId?: string;

  public cancellationReason?: string;

  public rate?: string;

  public creationReason?: string;

  public creationReasonText?: string;

  public sourceOfFundsDocID?: string;

  public extReferenceId?: string;

  public initiator?: {
    businessName: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    roleName: string;
    uid: string;
    userName: string;
  };

  public executor: {
    address: {
      address: string;
      address2NdLine: string;
      city: string;
      countryIso2: string;
      region: string;
      zipCode: string;
    };
    businessName: string;
    firstName: string;
    lastName: string;
    roleName: string;
  };

  public expiresAt?: string;

  public businessName?: string;

  public totals?: {
    amountToHandleToCashAgent: string;
    amountType: string;
    incomingAmount: string;
    requestAmount: string;
    walletFee: string;
    amountToReceiveFromCashAgent: string;
    totalOutgoingAmount: string;
  };

  public details?: {
    sourceIban: string;
    destinationIban: string;
    feesCoveredBy: string;
    parentRequestId: string; //For fees only
  };

  public getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public humanId(): string {
    const fullName = `${this.firstName.trim()} ${this.lastName.trim()}`.trim();
    if (fullName) {
      return `${fullName} ( ${this.email} )`;
    }

    return this.email;
  }
}

export class TransferDetails {
  public amount: CurrencyAmount;

  public baseCurrencyCode: string;

  public description: string;

  public fee: CurrencyAmount;

  public rate: CurrencyAmount;

}

export class TransferRequestData {
  public systemDetails: SystemDetails;

  public transferDetails: TransferDetails;

  public additionalDetails: AdditionalDetail[];

  public transactions: Transaction[];

  public feeTransaction: Transaction;
}

